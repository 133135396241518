import React from 'react'

const Student = () => {
  return (
    <div>
        student dashboard
    </div>
  )
}

export default Student