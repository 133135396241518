const faqData = [
    {
        id: 1,
        title: "How do I sign up for KidsCode?",
        description: "To register, please visit our website (www.myafrilearn.com/kidscode) and click on the SIGN UP NOW button. You will be required to put in a valid email address before you proceed to the application form."
    },
    {
        id: 2,
        title: "After registration, what next?",
        description: "Upon successful registration, your payment will be confirmed within 24 hours. After confirmation, a home study kit with all the materials you need for the assessment will be sent to you. Please make sure you provide a valid email address while applying and check your spam for emails from us. Also, ensure to thoroughly study the materials provided in the home study kit."
    }, 
    {
        id: 3,
        title: "When do the classes begin?",
        description: "The KidsCode class will commence in August. The actual date will be communicated with all applicants."
    },
    {
        id: 4,
        title: "How long will the course last for?",
        description: "The duration of the course is 2 months."
    },
    {
        id: 5,
        title: "Will I get a Certification at the end of the training?",
        description: "Yes, you will. Upon completion of the 3 months training, participants will gain an official Certificate from Afrilearn. Note that you will be required to pay the sum of ₦1000 to access certification from Afrilearn."
    },
    {
        id: 6,
        title: "Do I need to have prior knowledge of coding?",
        description: "No, you do not need prior knowledge about this course to apply. You will be taught from scratch."
    },
    {
        id: 7,
        title: "What qualifies me to get accepted into the program?",
        description: "To get accepted into the program, you need to be between above age 6 and be eager to learn and change the world."
    },
    {
        id: 8,
        title: "How much is the tuition fee?",
        description: "We have flexible payment plans just for you. You are required to choose your preferred payment plan, the tuition fee can be paid upfront, quarterly or monthly. Check out the tuition page to see all payment options."
    },
    {
        id: 9,
        title: "Is the certificate recognisable anywhere?",
        description: "Yes, it is. We are partnering with reputable higher institutions across the continent to increase credibility."
    },
    {
        id: 10,
        title: "Where will the classes take place?",
        description: "Most of the classes will be held online but we will also have community peer learning sessions from time to time."
    },
    {
        id: 11,
        title: "What will I learn in class for two months?",
        description: "You will learn the required skills to become a proficient with HTML, CSS, JavaScript etc"
    },
    
]

export default faqData;