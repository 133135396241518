import React from 'react';
import './scrollmessage.css';


const ScrollMessage = () => {
  return (
    <div className="scrollwrapper">
        <h1 behavior="scroll" direction="left" >Afrilearn KidsCode is live!‍💻💃</h1>
    </div>
  )
}

export default ScrollMessage