export const courseDataHtml = [
    {
        id: 1,
        content: "Computer Software",
        mode: "Animation, Storytelling"
    },
    {
        id: 2,
        content: "Introduction to HTML",
        mode: "Animation, Storytelling"
    },
    {
        id: 3,
        content: "HTML Headings",
        mode: "Animation, Storytelling"
    },
    {
        id: 4,
        content: "HTML Comments, Styling",
        mode: "Animation, Storytelling"
    },
    {
        id: 5,
        content: "HTML Link syntax",
        mode: "Animation, Storytelling"
    },
    {
        id: 6,
        content: "HTML Images, Images Map",
        mode: "Animation, Storytelling"
    },
    {
        id: 7,
        content: "HTML Identity and Classes",
        mode: "Animation, Storytelling"
    },
    {
        id: 8,
        content: "HTML List and Tables",
        mode: "Animation, Storytelling"
    },
]

export const courseDataCss = [
    {
        id: 1,
        content: "Introduction to CSS",
        mode: "Animation, Storytelling"
    },
    {
        id: 2,
        content: "CSS Syntax and comment",
        mode: "Animation, Storytelling"
    },
    {
        id: 3,
        content: "CSS Selectors",
        mode: "Animation, Storytelling"
    },
    {
        id: 4,
        content: "CSS  Navbar",
        mode: "Animation, Storytelling"
    },
    {
        id: 5,
        content: "CSS colours",
        mode: "Animation, Storytelling"
    },
    {
        id: 6,
        content: "CSS Backgrounds",
        mode: "Animation, Storytelling"
    },
    {
        id: 7,
        content: "Border Styling in CSS",
        mode: "Animation, Storytelling"
    },
    {
        id: 8,
        content: "Adding padding and margin spaces using CSS",
        mode: "Animation, Storytelling"
    },
]

export const courseDataJs = [
    {
        id: 1,
        content: "Introduction to Javascript",
        mode: "Animation, Storytelling"
    },
    {
        id: 2,
        content: "Javascript comments and syntax",
        mode: "Animation, Storytelling"
    },
    {
        id: 3,
        content: "Javascript Variables",
        mode: "Animation, Storytelling"
    },
    {
        id: 4,
        content: "Javascript operators",
        mode: "Animation, Storytelling"
    },
    {
        id: 5,
        content: "Javascript data types",
        mode: "Animation, Storytelling"
    },
    {
        id: 6,
        content: "Introductions to Functions in Javascript.",
        mode: "Animation, Storytelling"
    },
    {
        id: 7,
        content: "Javascript Objects",
        mode: "Animation, Storytelling"
    },
    {
        id: 8,
        content: "Javascript Strings",
        mode: "Animation, Storytelling"
    },
    {
        id: 9,
        content: "Javascript Array and Array methods",
        mode: "Animation, Storytelling"
    },
    {
        id: 10,
        content: "Loops in Javascript",
        mode: "Animation, Storytelling"
    },
    {
        id: 11,
        content: "Javascript scope and hoisting",
        mode: "Animation, Storytelling"
    },
    {
        id: 12,
        content: "Asynchronous functions",
        mode: "Animation, Storytelling"
    },
    {
        id: 13,
        content: "Javascript HTML DOM methods",
        mode: "Animation, Storytelling"
    },
    {
        id: 14,
        content: "Project",
        mode: "Animation, Storytelling"
    },
]