import React from 'react';
import './learning.css';

const LearningPath = () => {
  return (
    <div className='learningwrapper'>
        <div className='title-header'>
            <h1>About KidsCode</h1>
        </div>
        <div>
            <div className='para-learn-one'>
                <p>
                Afrilearn KidsCode is a live tutoring service that offers one-on-one coding lessons to kids aged
                6-18 in HTML, CSS, Javascript and Python. We also teach concepts in machine learning,
                artificial intelligence and so much more.
                </p>
            </div>
            <div className='para-learn-two'>
            <p>
            Position your child for a lifetime of success and greatness by exposing them to a world of
            coding.
            People who know how to code can create apps, games, websites, computer software and so
            much more.
            Afrilearn KidsCode personalised coding classes for kids are a great way to get started.
            Book a class now on Afrilearn KidsCode. Sign up now
                </p>
            </div>
        </div>
    </div>
  )
}

export default LearningPath